import {
  Alert,
  AlertIcon,
  CircularProgress,
  CloseButton,
  Divider,
  Flex,
  GatsbyImage,
  Link,
  Picto,
  Text,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import {
  CartContext,
  CheckoutCartSummary,
  DataLayerUtils,
  OrderItemsDetail,
  ProductCrossSell,
  SessionContext,
  UserContext,
  WebsiteContext
} from '@stars-ecom/shared-organisms-ui'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import toString from 'lodash/toString'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

const ShoppingCartPage = (props) => {
  const { pageContext } = props
  const { loyaltyCard, tvShow, loyaltyPage, displayTvShows, website, checkout } = pageContext
  const currentCart = useContext(CartContext)
  const currentUser = useContext(UserContext)
  const sessionCxt = useContext(SessionContext)
  const [isLoading, setIsLoading] = useState(true)
  const [alert, setAlert] = useState()
  const [bgColor, setBgColor] = useState('#f6f3f1')
  const { isOpen: isOpenLoyalty, onOpen: onOpenLoyalty, onClose: onCloseLoyalty } = useDisclosure()

  const websiteContext = useContext(WebsiteContext)

  useEffect(() => {
    if (sessionCxt?.session?.token) {
      setIsLoading(false)
      setBgColor(isEmpty(currentCart?.lines) ? '#f6f3f1' : '#fff')
    }
  }, [sessionCxt?.session?.token])

  useEffect(() => {
    if (currentCart?.id) {
      DataLayerUtils.addShoppingCartEvent({
        order: currentCart,
        command: 'checkoutStep',
        customer: currentUser,
        checkoutStepNumber: 1,
        checkoutStepName: 'Récapitulatif'
      })
    }
  }, [currentCart?.id])

  useEffect(() => {
    if (currentCart?.code) {
      DataLayerUtils.addEvent({
        event: 'newEcomDatalayer',
        eventCommand: 'checkoutStep',
        checkoutStepNumber: 1,
        checkoutStepName: 'Récapitulatif',
        shoppingCart: {
          code: currentCart?.code,
          currencyCode: 'EUR',
          subTotal: currentCart?.subTotal,
          subTotalWithTax: currentCart?.subTotalWithTax,
          total: currentCart?.total,
          totalQuantity: currentCart?.totalQuantity,
          totalWithTax: currentCart?.totalWithTax,
          cartItems: currentCart?.lines?.map((p) => {
            const isTv = p.productVariant?.customFields?.offers?.find(
              (o) =>
                toString(o.channel) === toString(process.env.GATSBY_API_CHANNEL_ID) &&
                o.type === 'STANDARD'
            )?.isTv
            return {
              productId: p.id, //id vendure
              externalCode: p.productVariant?.product?.customFields?.externalCode, //sku
              name: p.productVariant?.name,
              quantity: p.quantity,
              quantityPrice: p.linePrice,
              quantityPriceWithTax: p.linePriceWithTax,
              currencyCode: p.productVariant?.currencyCode,
              price: p.customFields?.price,
              standardPrice: p.customFields?.standardPrice,
              discountPourcent: p.productVariant?.customFields?.offers?.find(
                (o) =>
                  toString(o.channel) === toString(process.env.GATSBY_API_CHANNEL_ID) &&
                  o.type === p.customFields?.type
              )?.discount,
              ecoTaxDeee: p.customFields?.deee,
              ecoTaxEcomob: p.customFields?.ecomob,
              totalShippingFee: p.customFields?.totalShippingFee,
              productUrlKey: p.productVariant?.product?.urlKey,
              imageUrl: p?.featuredAsset?.smallImage?.source,
              isTv: isTv ? 'yes' : 'no' //produit emission
            }
          })
        }
      })
    }
  }, [currentCart?.code])

  const BackLink = () => {
    return (
      <Flex
        display="flex"
        fontFamily="PT Sans, Arial, sans-serif"
        alignItems="center"
        marginTop="20px"
        pl={{ base: '20px', md: '0' }}
        pb={{ base: '20px', md: '0' }}>
        <Picto
          icon="chevronLeft"
          width="12px"
          height="12px"
          color={websiteContext.mainColor}
          style={{ marginRight: '4px' }}
        />
        <Link to="/" style={{ borderBottom: '1px solid #000', lineHeight: '1' }}>
          Continuer vos achats
        </Link>
      </Flex>
    )
  }

  return (
    <Flex backgroundColor={bgColor} direction="column" w="100%" pb="20px" align="center">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Mon panier" />
        <title>Panier</title>
      </Helmet>
      {isLoading ? (
        <CircularProgress isIndeterminate />
      ) : (
        <>
          {isEmpty(currentCart?.lines) ? (
            <Flex direction="column" w={{ base: '100%', md: '1000px' }}>
              {alert && (
                <Alert status={alert.status}>
                  <AlertIcon />
                  {alert.message}
                  <CloseButton
                    position="absolute"
                    right="8px"
                    top="8px"
                    onClick={() => {
                      setAlert(null)
                    }}
                  />
                </Alert>
              )}
              <Flex direction="column" w={{ md: '430px' }} m={{ base: '15px', md: '55px 15px' }}>
                <Text
                  as="h1"
                  color="#333"
                  fontFamily={{
                    base: 'PT Sans, Arial, sans-serif',
                    md: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
                  }}
                  fontWeight="700"
                  fontSize={{ base: '28px', md: '60px' }}
                  lineHeight={{ md: '1' }}>
                  Votre panier est vide pour le moment
                </Text>
                <Text
                  fontSize={{ base: '14px', md: '18px' }}
                  color="#333"
                  fontFamily={{
                    base: 'PT Sans, Arial, sans-serif',
                    md: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
                  }}
                  m="30px 0">
                  {websiteContext?.title} vous propose chaque jour des produits innovants pour votre
                  quotidien ! Découvrez vite nos meilleures offres !
                </Text>
                <Link to="/">
                  <Flex
                    display="inline"
                    height="35px"
                    p="5px 15px"
                    backgroundColor={websiteContext?.mainColor}
                    _hover={{ backgroundColor: websiteContext?.lightColor }}
                    color="#fff"
                    justify={{ md: 'center' }}
                    align="center"
                    cursor="pointer"
                    fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
                    fontSize="19px"
                    fontWeight={400}>
                    Je consulte les offres du jour
                  </Flex>
                </Link>
              </Flex>
              <Flex direction="row" justify="flex-end" display={{ base: 'flex', md: 'none' }}>
              <GatsbyImage
                  image={
                    checkout?.emptyCartBackgroundImage?.image?.childImageSharp?.gatsbyImageData
                  }
                  alt="animateurs"
                />
              </Flex>
            </Flex>
          ) : (
            <Flex
              direction="column"
              width={{ base: '100%', md: '1000px' }}
              maxWidth={{ base: '420px', md: '1000px' }}
              paddingLeft={{ base: 0, md: '15px' }}
              paddingRight={{ base: 0, md: '15px' }}
              align="flex-start"
              justify="space-between"
              style={{ marginTop: '10px' }}>
              {alert && (
                <Alert status={alert.status}>
                  <AlertIcon />
                  {alert.message}
                  <CloseButton
                    position="absolute"
                    right="8px"
                    top="8px"
                    onClick={() => {
                      setAlert(null)
                    }}
                  />
                </Alert>
              )}
              <Flex w="100%" direction={{ base: 'column', md: 'row' }}>
                <Flex
                  direction="column"
                  w="100%"
                  p={{ base: ' 0 10px', md: '0' }}
                  mr={['0', '20px']}>
                  <Flex
                    justifyContent={['center', 'space-between']}
                    m={{ base: '0 10px', md: '0' }}
                    p={{ base: '0 0 10px', md: '10px 20px' }}>
                    <Flex align="center">
                      <Text
                        as="span"
                        fontSize="22px"
                        fontWeight="900"
                        fontFamily="PT Sans, Arial, sans-serif">
                        Panier
                      </Text>
                      <Text as="span" fontSize="13px" color="#999" ml="4px">{` (${
                        currentCart?.customFields?.totalProductQuantity || 0
                      })`}</Text>
                    </Flex>
                    <Text
                      display={['none', 'block']}
                      fontFamily="PT Sans, Arial, sans-serif"
                      alignSelf="center">
                      Prix
                    </Text>
                  </Flex>
                  <Divider />
                  <OrderItemsDetail />
                  {!websiteContext.isMobile && <BackLink />}
                </Flex>
                <CheckoutCartSummary
                  setAlert={setAlert}
                  loyaltyCard={loyaltyCard}
                  onOpenLoyalty={onOpenLoyalty}
                  isOpenLoyalty={isOpenLoyalty}
                  onCloseLoyalty={onCloseLoyalty}
                  loyaltyPage={loyaltyPage}
                />
              </Flex>
              {websiteContext.isMobile && <BackLink />}
            </Flex>
          )}
        </>
      )}
      {!isLoading && (
        <>
          {!currentCart ||
            (isEmpty(currentCart?.lines) && !isEmpty(tvShow) && !isEmpty(tvShow.products) && (
              <Flex justify="center" align="center" backgroundColor="#eef4ff" width="100%">
                <ProductCrossSell
                  container="Page récapitulatif panier"
                  products={tvShow.products}
                  title1="Les produits de l'émission"
                  title2={
                    tvShow?.type == 'PER_DAY'
                      ? `du ${dayjs(tvShow?.code, 'YYYY-MM-DD').format('DD MMMM YYYY')}`
                      : website?.title
                  }
                />
              </Flex>
            ))}
        </>
      )}
    </Flex>
  )
}

ShoppingCartPage.pageType = 'cart'

export default ShoppingCartPage
